<template>
  <nav v-if="windowWidth <= 1000" class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <router-link to="/">
        <img src="../assets/img/logo.png" height="50" width="50" alt="logo" />
      </router-link>
      <!-- Burger menu -->
      <button
        class="btn navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="bi bi-list" style="font-size: 2rem"></i>
      </button>
      <!-- Menu -->
      <div id="navbarNav" class="collapse navbar-collapse">
        <Menu />
      </div>
    </div>
  </nav>
  <div v-else class="header">
    <div
      class="container-fluid d-flex align-items-stretch justify-content-between"
    >
      <div
        class="d-flex align-items-stretch justify-content-start flex-lg-grow-1"
      >
        <div class="d-flex align-items-stretch flex-shrink-0">
          <div class="d-flex align-items-center">
            <button
              v-if="asideBarActive == true"
              class="btn btn-light-primary"
              @click.prevent="hideAsideBar()"
            >
              <span class="svg-icon svg-icon-2" style="cursor: pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z"
                    fill="black"
                  />
                  <path
                    opacity="0.3"
                    d="M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z"
                    fill="black"
                  />
                </svg>
              </span>
            </button>
            <button
              v-if="asideBarActive == false"
              class="btn btn-light"
              @click.prevent="showAsideBar()"
            >
              <span class="svg-icon svg-icon-2" style="cursor: pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                    fill="black"
                  />
                  <path
                    opacity="0.3"
                    d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                    fill="black"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-stretch justify-content-end flex-lg-grow-1"
      >
        <div class="d-flex align-items-stretch flex-shrink-0">
          <div class="d-flex align-items-center ms-1 ms-lg-3">
            <button
              class="btn btn-light-danger"
              @click.prevent="disconnectUser()"
            >
              <span class="svg-icon svg-icon-2"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.3"
                    width="12"
                    height="2"
                    rx="1"
                    transform="matrix(-1 0 0 1 15.5 11)"
                    fill="black"
                  />
                  <path
                    d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z"
                    fill="black"
                  />
                  <path
                    d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
                    fill="#C4C4C4"
                  /></svg
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Menu from "@/components/Menu.vue";

export default {
  name: "Header",
  components: {
    Menu,
  },
  data() {
    return {
      asideBarActive: true,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters(["userAuth"]),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapActions(["disconnect"]),
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    disconnectUser() {
      this.disconnect();
    },
    hideAsideBar() {
      this.asideBarActive = false;
      this.$emit("emitAsideBarActive", {
        valueAsideBarActive: this.asideBarActive,
      });
    },
    showAsideBar() {
      this.asideBarActive = true;
      this.$emit("emitAsideBarActive", {
        valueAsideBarActive: this.asideBarActive,
      });
    },
  },
};
</script>
