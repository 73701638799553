<template>
  <div class="menu-item menu-accordion">
    <router-link
      to="/permits?size=30&page=1"
      class="menu-link"
      @click.prevent="showAllPermits()"
    >
      <div
        v-if="permitsFilter"
        :class="
          menuOn == 'allPermitsMenu' ? 'menu-title text-primary' : 'menu-title'
        "
        data-bs-toggle="collapse"
        data-bs-target="#allPermitsMenu"
        aria-expanded="false"
        aria-controls="allPermitsMenu"
      >
        <span class="menu-icon">
          <i class="bi bi-file-earmark-text-fill h3 pt-2"></i></span
        ><span>Tous les permis</span>
      </div>
    </router-link>
    <div
      v-if="permitsFilter"
      id="allPermitsMenu"
      class="collapse"
      :data-bs-toggle="permitsFilter && windowWidth <= 1000 && 'collapse'"
      :data-bs-target="permitsFilter && windowWidth <= 1000 && '#navbarNav'"
    >
      <router-link
        to="/permits?size=30&page=1"
        :class="
          permitsFilter.dep__in == '75,77,78,91,92,93,94,95'
            ? 'menu-link text-primary bg-light-primary'
            : 'menu-link'
        "
        @click.prevent="filterRegion('75,77,78,91,92,93,94,95')"
        ><span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span>Île-de-France</span></router-link
      >
      <router-link
        to="/permits?size=30&page=1"
        :class="
          permitsFilter.dep__in == '1,3,7,15,26,38,42,43,63,69,73,74'
            ? 'menu-link text-primary bg-light-primary'
            : 'menu-link'
        "
        @click.prevent="filterRegion('1,3,7,15,26,38,42,43,63,69,73,74')"
        ><span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span>Auvergne-Rhône-Alpes</span></router-link
      >
      <router-link
        to="/permits?size=30&page=1"
        :class="
          permitsFilter.dep__in == '4,5,6,13,83,84'
            ? 'menu-link text-primary bg-light-primary'
            : 'menu-link'
        "
        @click.prevent="filterRegion('4,5,6,13,83,84')"
        ><span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span>Provence-Alpes-Côte d'Azur</span></router-link
      >
      <router-link
        to="/permits?size=30&page=1"
        :class="
          permitsFilter.dep__in == '9,11,12,30,31,32,34,46,48,65,66,81,82'
            ? 'menu-link text-primary bg-light-primary'
            : 'menu-link'
        "
        @click.prevent="filterRegion('9,11,12,30,31,32,34,46,48,65,66,81,82')"
        ><span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span>Occitanie</span></router-link
      >
      <router-link
        to="/permits?size=30&page=1"
        :class="
          permitsFilter.dep__in == '16,17,19,23,24,33,40,47,64,79,86,87'
            ? 'menu-link text-primary bg-light-primary'
            : 'menu-link'
        "
        @click.prevent="filterRegion('16,17,19,23,24,33,40,47,64,79,86,87')"
        ><span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span>Nouvelle Aquitaine</span></router-link
      >
      <router-link
        to="/permits?size=30&page=1"
        :class="
          permitsFilter.dep__in == '22,29,35,56'
            ? 'menu-link text-primary bg-light-primary'
            : 'menu-link'
        "
        @click.prevent="filterRegion('22,29,35,56')"
        ><span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span>Bretagne</span></router-link
      >
      <router-link
        to="/permits?size=30&page=1"
        :class="
          permitsFilter.dep__in == '44,49,53,72,85'
            ? 'menu-link text-primary bg-light-primary'
            : 'menu-link'
        "
        @click.prevent="filterRegion('44,49,53,72,85')"
        ><span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span>Pays de la Loire</span></router-link
      >
      <router-link
        to="/permits?size=30&page=1"
        :class="
          permitsFilter.dep__in == '2,8,10,14,18,20,21,25,27,28,36,37,39'
            ? 'menu-link text-primary bg-light-primary'
            : 'menu-link'
        "
        @click.prevent="filterRegion('2,8,10,14,18,20,21,25,27,28,36,37,39')"
        ><span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span>Autres</span></router-link
      >
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AllPermitsMenu",
  props: {
    menuOn: {
      type: String,
      default: "allPermitsMenu",
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters(["permits"]),
    ...mapGetters(["permitsFilter"]),
  },
  methods: {
    ...mapActions(["getPermits"]),
    getAllPermits() {
      store.commit("permits", null);
      this.getPermits(this.permitsFilter);
      this.permitsData = this.permits;
    },
    showAllPermits() {
      this.permitsFilter.nb_lls__gt = "";
      this.permitsFilter.city__decote_prix_ancien__gt = "";
      this.permitsFilter.city__decote_prix_ancien__lt = "";
      this.permitsFilter.dep__in = "";
      this.permitsFilter.specific_case__in = "";
      store.commit("permitsFilter", this.permitsFilter);
      store.commit("permits", null);
      this.getAllPermits();
      this.$emit("menuOnChanged", "allPermitsMenu");
    },
    filterRegion(departements) {
      this.permitsFilter.dep = "";
      this.permitsFilter.nb_lls__gt = "";
      this.permitsFilter.city__decote_prix_ancien__gt = "";
      this.permitsFilter.city__decote_prix_ancien__lt = "";
      this.permitsFilter.specific_case__in = "";
      // Diff
      this.permitsFilter.dep__in = departements;
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
      this.$emit("menuOnChanged", "");
    },
  },
};
</script>
