<template>
  <div class="d-flex flex-column flex-lg-row-fluid py-10">
    <!-- Content -->
    <div class="mx-4 flex-column flex-column-fluid">
      <div class="w-lg-500px p-10 p-lg-15 mx-auto">
        <form class="form w-100">
          <div class="text-center mb-10">
            <router-link to="/">
              <img
                class=""
                src="../assets/img/logo.png"
                height="150"
                width="150"
                alt="logo"
              />
            </router-link>
            <h1 class="text-dark mb-3">Connexion</h1>
          </div>
          <Message :message="message" />
          <div class="fv-row mb-10">
            <label class="form-label fs-6 fw-bolder text-dark">Email</label>
            <input
              v-model="form.email"
              class="form-control form-control-lg form-control-solid"
              type="email"
              required="required"
            />
          </div>
          <div class="fv-row mb-10">
            <div class="d-flex flex-stack mb-2">
              <label class="form-label fw-bolder text-dark fs-6 mb-0"
                >Mot de passe</label
              >
              <router-link to="/" class="link-primary fs-6 fw-bolder"
                >Mot de passe oublié ?</router-link
              >
            </div>
            <div class="input-group d-flex">
              <input
                v-model="form.password"
                class="form-control form-control-lg form-control-solid"
                :type="passwordInput"
                autocomplete="on"
                required="required"
              />
              <button
                style="margin-left: -50px"
                type="button"
                class="btn"
                @click="changePasswordInput()"
              >
                <i
                  :class="
                    passwordInput == 'password'
                      ? 'bi bi-eye-fill'
                      : 'bi bi-eye-slash-fill'
                  "
                  style="font-size: 1.3rem"
                ></i>
              </button>
            </div>
          </div>
          <div class="text-center">
            <button
              type="submit"
              class="btn btn-lg btn-primary fw-bolder w-100 me-3 my-2"
              @click.prevent="connect()"
            >
              Se connecter
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Footer -->
    <div class="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
      <div class="d-flex flex-center fw-bold fs-6">
        <span class="fs-6 fw-bolder"
          >Powered by
          <a href="https://sohoinvest.fr" class="link-primary" target="_blank"
            >Soho Invest</a
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Message from "@/components/Message";

export default {
  name: "SignIn",
  components: {
    Message,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      passwordInput: "password",
    };
  },
  computed: {
    ...mapGetters(["message"]),
  },
  methods: {
    ...mapActions(["login"]),
    async connect() {
      this.login(this.form);
    },
    changePasswordInput() {
      if (this.passwordInput == "password") {
        this.passwordInput = "text";
      } else {
        this.passwordInput = "password";
      }
    },
  },
};
</script>
