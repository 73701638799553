import { request } from "../services/axiosService";

export const getPromoters = async (filter) => {
  const route = "/promoters/";

  const query = {
    ordering: filter.order === "" ? "-id" : filter.order,
    search: filter.search === "" ? undefined : filter.search,
    fields: "id,name,head_office_address,director_name,created,updated",
    page_size: filter.size === "" ? filter.defaultSize : filter.size,
    page: filter.page === "" ? undefined : filter.page,
  };

  return request("api", "get", true, route, query);
};

export const searchPromoter = async (input) => {
  const route = "/promoters/?search=" + input;
  return request("api", "get", true, route);
};

export const getPromoter = async (id) => {
  const route = "/promoters/" + id + "/";
  return request("api", "get", true, route);
};

export const postPromoter = async (
  name,
  director_name,
  head_office_address
) => {
  const route = "/promoters/";
  const body = {
    name,
    director_name: director_name === "" ? undefined : director_name,
    head_office_address:
      head_office_address === "" ? undefined : head_office_address,
  };
  return request("api", "post", true, route, null, body);
};

export const updatePromoter = async (
  id,
  name,
  director_name,
  head_office_address
) => {
  const route = "/promoters/" + id + "/";
  const body = {
    name,
    director_name: director_name === "" ? undefined : director_name,
    head_office_address:
      head_office_address === "" ? undefined : head_office_address,
  };
  return request("api", "patch", true, route, null, body);
};

export const deletePromoter = async (id) => {
  const route = "/promoters/" + id + "/";
  return request("api", "delete", true, route);
};

export const getPromoterContacts = async (id) => {
  const route = "/contacts/?promoter=" + id;
  const query = {
    fields:
      "id,first_name,last_name,phone_number,position,agency,last_followup_author,relation_quality",
  };
  return request("api", "get", true, route, query);
};

export const getPromoterAgencies = async (id) => {
  const route = "/agencies/?promoter=" + id;
  return request("api", "get", true, route);
};

export const getPromoterPermits = async (filter) => {
  const route = "/permits/";
  const query = {
    principal_contact__promoter: filter.id,
    ordering: filter.order === "" ? "-date_reelle_autorisation" : filter.order,
    page_size: filter.size === "" ? filter.defaultSize : filter.size,
  };
  return request("api", "get", true, route, query);
};

export const getPromotersInSelect = async () => {
  const route = "/promoters/";
  const query = {
    ordering: "name",
    fields: "id,name",
  };
  return request("api", "get", true, route, query);
};

export default {
  getPromoters,
  searchPromoter,
  getPromoter,
  postPromoter,
  updatePromoter,
  deletePromoter,
  getPromoterContacts,
  getPromoterAgencies,
  getPromoterPermits,
  getPromotersInSelect,
};
