<template>
  <div class="menu-item menu-accordion">
    <router-link
      to="/cities?size=30&page=1"
      class="menu-link"
      @click.prevent="getAllCities()"
    >
      <span
        :class="
          menuOn == 'allCities' ? 'menu-title text-primary' : 'menu-title'
        "
        data-bs-toggle="collapse"
        data-bs-target="#citiesMenus"
        aria-expanded="false"
        aria-controls="citiesMenus"
      >
        <span class="menu-icon">
          <i class="bi bi-building-fill h3 pt-2"></i>
        </span>
        Mairies
      </span>
    </router-link>
    <div
      id="citiesMenus"
      class="collapse"
      :data-bs-toggle="filter && windowWidth <= 1000 && 'collapse'"
      :data-bs-target="filter && windowWidth <= 1000 && '#navbarNav'"
    >
      <router-link
        to="/cities?size=30&page=1"
        :class="
          filter.mayor_relation_quality__in == '1,2,3,4,5'
            ? 'menu-link text-primary bg-light-primary'
            : 'menu-link'
        "
        @click.prevent="filterRelation('1,2,3,4,5')"
        ><span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span>Mairies en relations</span></router-link
      >
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CitiesRelated",
  props: {
    menuOn: String,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      filter: {
        mayor_relation_quality__in: "",
      },
    };
  },
  computed: {
    ...mapGetters(["cities"]),
  },
  methods: {
    ...mapActions(["getCities"]),

    getAllCities() {
      this.filter.mayor_relation_quality__in = null;
      this.getCities(this.filter);
      this.$emit("menuOnChanged", "allCities");
    },
    filterRelation(mayor_relation_quality__in) {
      this.filter.mayor_relation_quality__in = mayor_relation_quality__in;
      this.getCities(this.filter);
      store.commit("cities", null);
      this.$emit("menuOnChanged", "");
    },
  },
};
</script>
