// Users
export const users = (state, users) => {
  state.users = users;
};

export const user = (state, user) => {
  state.user = user;
};

// Permits
export const permits = (state, permits) => {
  state.permits = permits;
};

export const permit = (state, permit) => {
  state.permit = permit;
};

export const permitsFilter = (state, permitsFilter) => {
  state.permitsFilter = permitsFilter;
};

export const permitContacts = (state, permitContacts) => {
  state.permitContacts = permitContacts;
};

export const permitFollowUps = (state, permitFollowUps) => {
  state.permitFollowUps = permitFollowUps;
};

// Promoters
export const promoters = (state, promoters) => {
  state.promoters = promoters;
};

export const promoter = (state, promoter) => {
  state.promoter = promoter;
};

export const promoterContacts = (state, promoterContacts) => {
  state.promoterContacts = promoterContacts;
};

export const promoterAgencies = (state, promoterAgencies) => {
  state.promoterAgencies = promoterAgencies;
};

export const promoterPermits = (state, promoterPermits) => {
  state.promoterPermits = promoterPermits;
};

// Contacts
export const contacts = (state, contacts) => {
  state.contacts = contacts;
};

export const contact = (state, contact) => {
  state.contact = contact;
};

export const contactCallbackDates = (state, contactCallbackDates) => {
  state.contactCallbackDates = contactCallbackDates;
};

export const contactPermits = (state, contactPermits) => {
  state.contactPermits = contactPermits;
};

// Agencies
export const agencies = (state, agencies) => {
  state.agencies = agencies;
};

export const agency = (state, agency) => {
  state.agency = agency;
};

export const agencyContacts = (state, agencyContacts) => {
  state.agencyContacts = agencyContacts;
};

export const agencyPermits = (state, agencyPermits) => {
  state.agencyPermits = agencyPermits;
};

// Cities
export const cities = (state, cities) => {
  state.cities = cities;
};

export const city = (state, city) => {
  state.city = city;
};

export const citiesFilter = (state, citiesFilter) => {
  state.citiesFilter = citiesFilter;
};

export const cityPositions = (state, cityPositions) => {
  state.cityPositions = cityPositions;
};

export const cityContacts = (state, cityContacts) => {
  state.cityContacts = cityContacts;
};

export const citiesContacts = (state, citiesContacts) => {
  state.citiesContacts = citiesContacts;
};

export const cityCallbackDates = (state, cityCallbackDates) => {
  state.cityCallbackDates = cityCallbackDates;
};

export const cityComments = (state, cityComments) => {
  state.cityComments = cityComments;
};

export const citiesCallbackDates = (state, citiesCallbackDates) => {
  state.citiesCallbackDates = citiesCallbackDates;
};
// Addresses
export const addresses = (state, addresses) => {
  state.addresses = addresses;
};

export const address = (state, address) => {
  state.address = address;
};

// Call back dates
export const callBackDates = (state, callBackDates) => {
  state.callBackDates = callBackDates;
};

export const callBackDatesNegotiation = (state, callBackDatesNegotiation) => {
  state.callBackDatesNegotiation = callBackDatesNegotiation;
};

export const callBackDatesFavoris = (state, callBackDatesFavoris) => {
  state.callBackDatesFavoris = callBackDatesFavoris;
};

export const callBackDatesLeft = (state, callBackDatesLeft) => {
  state.callBackDatesLeft = callBackDatesLeft;
};

export const userAuth = (state, userAuth) => {
  state.userAuth = userAuth;
};

export const message = (state, message) => {
  state.message = message;
};
