<template>
  <div class="menu-item menu-accordion">
    <router-link
      to="/permits?size=30&page=1"
      class="menu-link"
      @click.prevent="showPermitsMenuFavoritesAndPending()"
    >
      <div
        v-if="permitsFilter"
        :class="
          menuOn == 'permitsMenuFavoritesAndPending'
            ? 'menu-title text-primary'
            : 'menu-title'
        "
        data-bs-toggle="collapse"
        data-bs-target="#showPermitsMenuFavoritesAndPending"
        aria-expanded="false"
        aria-controls="showPermitsMenuFavoritesAndPending"
      >
        <span class="menu-icon"> <i class="bi bi-star-fill h3"></i></span
        ><span>Opérations favorites, en développement et en cours</span>
      </div>
    </router-link>
    <div
      v-if="permitsFilter"
      id="showPermitsMenuFavoritesAndPending"
      class="collapse"
      :data-bs-toggle="permitsFilter && windowWidth <= 1000 && 'collapse'"
      :data-bs-target="permitsFilter && windowWidth <= 1000 && '#navbarNav'"
    >
      <router-link
        to="/permits?size=30&page=1"
        :class="
          permitsFilter.specific_case__in == 'Permis favoris'
            ? 'menu-link text-primary bg-light-primary'
            : 'menu-link'
        "
        @click.prevent="filterSpecificCase('Permis favoris')"
        ><span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span>Permis favoris</span></router-link
      >
      <router-link
        to="/permits?size=30&page=1"
        :class="
          permitsFilter.specific_case__in == 'Négociation en cours'
            ? 'menu-link text-primary bg-light-primary'
            : 'menu-link'
        "
        @click.prevent="filterSpecificCase('Négociation en cours')"
        ><span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span>Négociation en cours</span></router-link
      >
      <router-link
        to="/permits?size=30&page=1"
        :class="
          permitsFilter.specific_case__in == 'Opérations signées'
            ? 'menu-link text-primary bg-light-primary'
            : 'menu-link'
        "
        @click.prevent="filterSpecificCase('Opérations signées')"
        ><span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span>Opérations signées</span></router-link
      >
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PermitsMenuFavoritesAndPending",
  props: {
    menuOn: String,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters(["permits"]),
    ...mapGetters(["permitsFilter"]),
  },
  methods: {
    ...mapActions(["getPermits"]),
    getAllPermits() {
      store.commit("permits", null);
      this.getPermits(this.permitsFilter);
      this.permitsData = this.permits;
    },
    showPermitsMenuFavoritesAndPending() {
      this.permitsFilter.nb_lls__gt = "";
      this.permitsFilter.city__decote_prix_ancien__gt = "";
      this.permitsFilter.city__decote_prix_ancien__lt = "";
      this.permitsFilter.dep__in = "";
      this.permitsFilter.specific_case__in =
        "Permis favoris,Négociation en cours,Opérations signées";
      store.commit("permitsFilter", this.permitsFilter);
      store.commit("permits", null);
      this.getAllPermits();
      this.$emit("menuOnChanged", "permitsMenuFavoritesAndPending");
    },
    filterSpecificCase(specificCase) {
      this.permitsFilter.nb_lls__gt = "";
      this.permitsFilter.city__decote_prix_ancien__gt = "";
      this.permitsFilter.city__decote_prix_ancien__lt = "";
      this.permitsFilter.dep__in = "";
      this.permitsFilter.specific_case__in = specificCase;
      store.commit("permitsFilter", this.permitsFilter);
      store.commit("permits", null);
      this.getAllPermits();
      this.$emit("menuOnChanged", "");
    },
  },
};
</script>
