import { createRouter, createWebHistory } from "vue-router";
import SignIn from "../views/SignIn.vue";
import store from "../store";

const routes = [
  // Login
  {
    path: "/",
    name: "SignIn",
    component: SignIn,
    meta: {
      title: "Connexion",
    },
  },
  // Home
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      title: "Accueil",
    },
  },
  // Agencies
  {
    path: "/agencies",
    name: "Agencies",
    component: () => import("../views/agencies/Agencies.vue"),
    meta: {
      title: "Agences",
    },
  },
  {
    path: "/agencies/:id",
    name: "AgenciesInfos",
    component: () => import("../views/agencies/AgenciesInfos.vue"),
    meta: {
      title: "Infos agences",
    },
  },
  {
    path: "/agencies/form/:id",
    name: "AgenciesForm",
    component: () => import("../views/agencies/AgenciesForm.vue"),
    meta: {
      title: "Formulaire de modification d'une agence",
    },
  },
  // Cities
  {
    path: "/cities",
    name: "Cities",
    component: () => import("../views/cities/Cities.vue"),
    meta: {
      title: "Mairies",
    },
  },
  {
    path: "/cities/:id",
    name: "CitiesInfos",
    component: () => import("../views/cities/CitiesInfos.vue"),
    meta: {
      title: "Mairie info",
    },
  },
  {
    path: "/cities/contacts",
    name: "CitiesContacts",
    component: () => import("../views/cities/CitiesContacts.vue"),
    meta: {
      title: "Contacts Mairie",
    },
  },
  // Contacts
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("../views/contacts/Contacts.vue"),
    meta: {
      title: "Contacts",
    },
  },
  {
    path: "/contacts/:id",
    name: "ContactsInfos",
    component: () => import("../views/contacts/ContactsInfos.vue"),
    meta: {
      title: "Info contact",
    },
  },
  {
    path: "/contacts/form/add",
    name: "ContactsForm",
    component: () => import("../views/contacts/ContactsForm.vue"),
    meta: {
      title: "Formulaire d'ajout d'un contact",
    },
  },
  {
    path: "/contacts/form/:id",
    name: "ContactsForm",
    component: () => import("../views/contacts/ContactsForm.vue"),
    meta: {
      title: "Formulaire de modification d'un contact",
    },
  },
  // Permits
  {
    path: "/permits",
    name: "Permits",
    component: () => import("../views/permits/Permits.vue"),
    meta: {
      title: "Permis",
    },
  },
  {
    path: "/permits/:id",
    name: "PermitsInfos",
    component: () => import("../views/permits/PermitsInfos.vue"),
    meta: {
      title: "Info permis",
    },
  },
  // Promoters
  {
    path: "/promoters",
    name: "Promoters",
    component: () => import("../views/promoters/Promoters.vue"),
    meta: {
      title: "Promoteurs",
    },
  },
  {
    path: "/promoters/:id",
    name: "PromotersInfos",
    component: () => import("../views/promoters/PromotersInfos"),
    meta: {
      title: "Info promoteur",
    },
  },
  {
    path: "/promoters/form/add",
    name: "PromotersForm",
    component: () => import("../views/promoters/PromotersForm.vue"),
    meta: {
      title: "Formulaire d'ajout d'un promoteur",
    },
  },
  {
    path: "/promoters/form/:id",
    name: "PromotersFormId",
    component: () => import("../views/promoters/PromotersForm.vue"),
    meta: {
      title: "Formulaire de modification d'un promoteur",
    },
  },
  // Users
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Users.vue"),
    meta: {
      title: "Utilisateurs",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "Soho Invest | " + to.meta.title;
  next();
});

const routeAccepted = ["SignIn"];
const adminRouteAccepted = [
  // Users
  "Users",
  // Cities
  "Cities",
  "CitiesInfos",
  "CitiesContacts",
  // Permits
  "Permits",
  "PermitsInfos",
  // Promoters
  "Promoters",
  "PromotersInfos",
  "PromotersForm",
  "PromotersFormId",
  // Contacts
  "Home",
  "Contacts",
  "ContactsInfos",
  "ContactsForm",
  // Agencies
  "Agencies",
  "AgenciesInfos",
  "AgenciesForm",
];

router.beforeEach((to, from, next) => {
  store.commit("users", null);
  store.commit("user", null);
  store.commit("cities", null);
  store.commit("city", null);
  store.commit("address", null);
  store.commit("addresses", null);
  store.commit("agency", null);
  store.commit("promoters", null);
  store.commit("promoter", null);
  store.commit("permits", null);
  store.commit("permit", null);
  store.commit("message", null);
  if (!routeAccepted.includes(to.name) && !store.state.userAuth) {
    next({ name: "SignIn" });
  } else {
    if (store.state.userAuth) {
      if (adminRouteAccepted.includes(to.name)) {
        next();
      } else {
        next({ name: "Home" });
      }
    } else {
      next();
    }
  }
});

export default router;
