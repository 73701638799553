import axios from "axios";
import authService from "./authService";
import loggerService from "./loggerService";

const serviceBaseUrlMapping = {
  api: process.env.VUE_APP_API_URL,
};

export const request = async (
  service,
  method,
  needAuth,
  route,
  query,
  body
) => {
  loggerService.info(
    "[AXIOS] (" + service + ") " + method.toUpperCase() + " " + route
  );

  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const headers = {
    ...defaultHeaders,
  };

  if (needAuth) {
    const auth = await authService.authenticate();

    if (auth) {
      headers.Authorization = `Token ${auth.token}`;
    } else {
      return null;
    }
  }

  try {
    const res = await axios.request({
      method,
      baseURL: serviceBaseUrlMapping[service],
      url: route,
      headers,
      params: query,
      data: body,
      validateStatus: (status) => {
        return status < 500;
      },
    });

    if (res.status === 403 && ["user_not_found"].includes(res.data.code)) {
      authService.removeAuth();
      return null;
    }

    return {
      status: res.status,
      content: res.data,
    };
  } catch (err) {
    if (err.response) {
      return {
        status: err.response.status,
      };
    }

    return {};
  }
};

export default { request };
