export const info = (...m) => {
  console.info(...m);
  //crashlytics().log(`info - ${m[0]}`);
};

export const warn = (...m) => {
  console.warn(...m);
  //crashlytics().log(`warn - ${m[0]}`);
};

export const error = (...m) => {
  console.error(...m);
  //crashlytics().log(`error - ${m[0]}`);
};

export default { info, warn, error };
