import { request } from "@/services/axiosService";

// Paginations
export const nextPaginate = async (url) => {
  const route = url;
  return request("api", "get", true, route);
};

export const previousPaginate = async (url) => {
  const route = url;
  return request("api", "get", true, route);
};

export default {
  nextPaginate,
  previousPaginate,
};
