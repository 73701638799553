<template>
  <div></div>
</template>

<script>
import store from "@/store";
import { useToast } from "vue-toastification";

export default {
  name: "Toast",
  props: {
    message: { default: null },
  },
  mounted() {
    this.sendToast();
  },
  methods: {
    sendToast() {
      const toast = useToast();
      // Quand c'est un array
      if (Array.isArray(this.message.text)) {
        for (const element of this.message.text) {
          // eslint-disable-next-line vue/no-mutating-props
          if (
            this.message.status == "error" ||
            this.message.status == "danger"
          ) {
            toast.error(element, {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          } else if (this.message.status == "success") {
            toast.success(element, {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            store.commit("message", null);
          }
        }
        store.commit("message", null);
      } // Quand c'est un object
      else if (
        this.message.status == "error" ||
        this.message.status == "danger"
      ) {
        toast.error(this.message.text, {
          position: "bottom-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        store.commit("message", null);
      } else if (this.message.status == "success") {
        toast.success(this.message.text, {
          position: "bottom-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        store.commit("message", null);
      }
    },
  },
};
</script>
