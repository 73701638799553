import { createStore } from "vuex";
import * as state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ userAuth: state.userAuth }),
});

export default createStore({
  state,
  getters,
  mutations,
  actions,
  plugins: [vuexLocal.plugin],
});
