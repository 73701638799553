import { request } from "../services/axiosService";

export const getAgencies = async (/*filter*/) => {
  const route = "/agencies/";

  const query = {
    ordering: "-id",
  };

  return request("api", "get", true, route, query);
};

export const searchAgency = async (input) => {
  const route = "/agencies/?search=" + input;
  return request("api", "get", true, route);
};

export const getAgency = async (id) => {
  const route = "/agencies/" + id + "/";

  return request("api", "get", true, route);
};

export const postAgency = async (name, phone_number, promoter, address) => {
  const route = "/agencies/";
  const body = {
    name,
    phone_number,
    promoter,
    address,
  };
  return request("api", "post", true, route, null, body);
};

export const updateAgency = async (
  id,
  name,
  phone_number,
  promoter,
  address
) => {
  const route = "/agencies/" + id + "/";

  const body = {
    name,
    phone_number,
    promoter,
    address,
  };

  return request("api", "patch", true, route, null, body);
};

export const deleteAgency = async (id) => {
  const route = "/agencies/" + id + "/";
  return request("api", "delete", true, route);
};

export const getAgencyContacts = async (id) => {
  const route = "/contacts/?agency=" + id;
  const query = {
    fields:
      "id,first_name,last_name,phone_number,position,agency,relation_quality",
  };
  return request("api", "get", true, route, query);
};

export const getAgencyPermits = async (filter) => {
  const route = "/permits/";
  const query = {
    principal_contact__agency: filter.id,
    ordering: filter.order === "" ? "-date_reelle_autorisation" : filter.order,
  };
  return request("api", "get", true, route, query);
};

export const getAgenciesInSelect = async () => {
  const route = "/agencies/";
  const query = {
    ordering: "name",
    fields: "id,name",
  };
  return request("api", "get", true, route, query);
};

export default {
  getAgencies,
  searchAgency,
  getAgency,
  postAgency,
  updateAgency,
  deleteAgency,
  getAgencyContacts,
  getAgencyPermits,
  getAgenciesInSelect,
};
