import store from "../store";
import router from "../router";

export const getAuth = () => {
  return store.state.userAuth;
};

export const setAuth = (auth) => {
  store.commit("userAuth", {
    ...auth,
  });
};

export const removeAuth = () => {
  store.commit("userAuth", null);
  localStorage.removeItem("vuex");
  router.push({ name: "SignIn" });
};

export const authenticate = async () => {
  const auth = getAuth();

  if (!auth) {
    return null;
  }

  return auth;
};

export const login = async (auth) => {
  setAuth(auth);
};

export const logout = async () => {
  removeAuth();
};

export default {
  getAuth,
  setAuth,
  removeAuth,
  authenticate,
  login,
  logout,
};
