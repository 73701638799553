import store from "../store";

// Users
export const getUsers = (users) => {
  store.commit("users", {
    ...users,
  });
};

export const setUsers = (users) => {
  store.commit("users", {
    ...users,
  });
};

export const setUser = (user) => {
  store.commit("user", {
    ...user,
  });
};

// Permits
export const setPermits = (permits) => {
  store.commit("permits", {
    ...permits,
  });
};

export const setPermit = (permit) => {
  store.commit("permit", {
    ...permit,
  });
};

export const setPermitContacts = (permitContacts) => {
  store.commit("permitContacts", {
    ...permitContacts,
  });
};

// Promoters
export const setPromoters = (promoters) => {
  store.commit("promoters", {
    ...promoters,
  });
};

export const setPromoter = (promoter) => {
  store.commit("promoter", {
    ...promoter,
  });
};

export const setPromoterContacts = (promoterContacts) => {
  store.commit("promoterContacts", {
    ...promoterContacts,
  });
};

export const setPromoterAgencies = (promoterAgencies) => {
  store.commit("promoterAgencies", {
    ...promoterAgencies,
  });
};

export const setPromoterPermits = (promoterPermits) => {
  store.commit("promoterPermits", {
    ...promoterPermits,
  });
};

// Addresses
export const setAddresses = (addresses) => {
  store.commit("addresses", {
    ...addresses,
  });
};

export const setAddress = (address) => {
  store.commit("address", {
    ...address,
  });
};

// Agencies
export const setAgencies = (agencies) => {
  store.commit("agencies", {
    ...agencies,
  });
};

export const setAgency = (agency) => {
  store.commit("agency", {
    ...agency,
  });
};

export const setAgencyContacts = (agencyContacts) => {
  store.commit("agencyContacts", {
    ...agencyContacts,
  });
};

export const setAgencyPermits = (agencyPermits) => {
  store.commit("agencyPermits", {
    ...agencyPermits,
  });
};

// Call back dates
export const setCallBackDates = (callBackDates, type) => {
  store.commit(type, {
    ...callBackDates,
  });
};

// Cities
export const setCities = (cities) => {
  store.commit("cities", {
    ...cities,
  });
};

export const setCity = (city) => {
  store.commit("city", {
    ...city,
  });
};

export const setCityPositions = (cityPositions) => {
  store.commit("cityPositions", {
    ...cityPositions,
  });
};

export const setCityContacts = (cityContacts) => {
  store.commit("cityContacts", {
    ...cityContacts,
  });
};

export const setCitiesContacts = (citiesContacts) => {
  store.commit("citiesContacts", {
    ...citiesContacts,
  });
};

export const setCityCallbackDates = (cityCallbackDates) => {
  store.commit("cityCallbackDates", {
    ...cityCallbackDates,
  });
};

export const setCityComments = (cityComments) => {
  store.commit("cityComments", {
    ...cityComments,
  });
};

export const setCitiesCallbackDates = (citiesCallbackDates) => {
  store.commit("citiesCallbackDates", {
    ...citiesCallbackDates,
  });
};

// Contacts
export const setContacts = (contacts) => {
  store.commit("contacts", {
    ...contacts,
  });
};

export const setContact = (contact) => {
  store.commit("contact", {
    ...contact,
  });
};

export const setContactCallbackDates = (contactCallbackDates) => {
  store.commit("contactCallbackDates", {
    ...contactCallbackDates,
  });
};

export const setContactPermits = (contactPermits) => {
  store.commit("contactPermits", {
    ...contactPermits,
  });
};

// Follow ups
export const setPermitFollowUps = (permitFollowUps) => {
  store.commit("permitFollowUps", {
    ...permitFollowUps,
  });
};

export default {
  // Addresses
  setAddresses,
  setAddress,
  // Agencies
  setAgencies,
  setAgency,
  setAgencyContacts,
  setAgencyPermits,
  // Call back dates
  setCallBackDates,
  // Cities
  setCities,
  setCity,
  setCityContacts,
  setCitiesContacts,
  setCityPositions,
  setCityCallbackDates,
  setCitiesCallbackDates,
  setCityComments,
  // Contacts
  setContacts,
  setContact,
  setContactCallbackDates,
  setContactPermits,
  // Permits
  setPermits,
  setPermit,
  setPermitContacts,
  setPermitFollowUps,
  // Promoters
  setPromoters,
  setPromoter,
  setPromoterContacts,
  setPromoterAgencies,
  setPromoterPermits,
  // Users
  getUsers,
  setUsers,
  setUser,
};
