<template>
  <div
    v-if="userAuth"
    class="header-fixed header-tablet-and-mobile-fixed toolbar-enabled aside-default-enabled"
  >
    <div class="d-flex flex-column flex-root">
      <div class="page d-flex flex-row flex-column-fluid">
        <!-- Aside bar -->
        <AsideBar v-if="asideBarActive == true" />
        <!-- Page -->
        <div
          class="d-flex flex-column flex-row-fluid"
          :class="{ wrapper: asideBarActive == true }"
        >
          <Header @emitAsideBarActive="getValueAsideBarActive" />
          <router-view />
          <Toast v-if="message" :message="message" />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "@/assets/css/app.css";
import AsideBar from "@/components/AsideBar.vue";
import Header from "@/components/Header.vue";
import Toast from "@/components/Toast";

export default {
  name: "App",
  components: {
    AsideBar,
    Header,
    Toast,
  },
  data() {
    return {
      asideBarActive: true,
    };
  },
  computed: {
    ...mapGetters(["userAuth"]),
    ...mapGetters(["message"]),
  },
  methods: {
    ...mapActions(["disconnect"]),
    disconnectUser() {
      this.disconnect();
    },
    async getValueAsideBarActive(res) {
      this.asideBarActive = res.valueAsideBarActive;
    },
  },
};
</script>
