<template>
  <div class="aside aside-default aside-hoverable">
    <!-- Aside Logo -->
    <div class="aside-logo flex-column-auto pt-6 pb-5">
      <router-link to="/">
        <span class="text-primary fw-bolder fs-2"
          ><img
            src="../assets/img/logo.png"
            height="50"
            width="50"
            alt="logo"
          />
          Soho Invest</span
        >
      </router-link>
    </div>
    <!-- Aside Menu --->
    <div class="aside-menu flex-column-fluid mt-5">
      <Menu />
    </div>
    <div class="d-flex align-items-center menu-item fw-bold fs-3 mb-5">
      <span class="me-3" style="font-size: 13px"
        >Version :
        {{ isFrontVersion ? frontVersion : backVersion.version }}</span
      >
      <span
        :class="
          isFrontVersion
            ? 'badge bg-light-primary text-primary'
            : 'badge bg-light-danger text-danger'
        "
        style="cursor: pointer; font-size: 13px"
        @click.prevent="changeVersion()"
        >{{ isFrontVersion ? "Front" : "Back" }}</span
      >
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import packageJson from "../../package.json";
import { mapActions } from "vuex";

export default {
  name: "AsideBar",
  components: {
    Menu,
  },
  data() {
    return {
      isFrontVersion: true,
      frontVersion: packageJson.version,
      backVersion: "",
    };
  },
  methods: {
    ...mapActions(["getVersion"]),
    async getBackVersion() {
      this.backVersion = await this.getVersion();
    },
    async changeVersion() {
      if (this.isFrontVersion) {
        this.isFrontVersion = false;
      } else {
        this.isFrontVersion = true;
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    this.getBackVersion();
  },
};
</script>
