export const formatError = (errorResponse) => {
  const errors = [];

  Object.entries(errorResponse).forEach(([k, v]) => {
    if (Array.isArray(v)) {
      errors.push(k + " : " + v.join(" "));
      return;
    }
    if (typeof v == "string") {
      errors.push(v);
      return;
    }

    errors.push(...formatError(v));
  });

  return errors;
};

export default {
  formatError,
};
