<template>
  <div v-if="message != null">
    <div
      :class="
        'alert alert-dismissible bg-' + message.status + ' fade show p-5 mb-10'
      "
    >
      <div class="text-white">
        <div v-if="message.status == 'danger'">
          <div v-for="(text, index) in message.text" :key="index">
            <li>{{ text }}</li>
          </div>
        </div>
        <div v-else>
          {{ message.text }}
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="alert"
          @click="resetMessage()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "Message",
  props: {
    message: { default: null },
  },
  methods: {
    resetMessage() {
      return store.commit("message", null);
    },
  },
};
</script>
