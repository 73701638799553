import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import moment from "moment";
import "moment/locale/fr";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueMask from "@devindex/vue-mask";

moment.locale("fr");

createApp(App)
  .use(store)
  .use(router)
  .use(Toast)
  .use(VueMask)
  .component("v-select", vSelect)
  .mount("#app");
