import apiHelper from "@/helpers/apiHelper";
import agencyHelper from "@/helpers/agencyHelper";
import cityHelper from "../helpers/cityHelper";
import paginationsHelper from "@/helpers/paginationsHelper";
import promoterHelper from "@/helpers/promoterHelper";
import validationHelper from "@/helpers/validationHelper";
import authService from "../services/authService";
import adminService from "../services/adminService";
import store from "./index";
import router from "../router";

// Authentification
export const login = async ({}, form) => {
  const res = await apiHelper.login(form.email, form.password);

  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Connexion réussie !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: ["Les informations saisies ne sont pas correctes !"],
    });
    return false;
  }
  await authService.login(res.content);
  return router.push("/home");
};

export const disconnect = async () => {
  authService.logout();
  return router.push("/login");
};

// Version
export const getVersion = async ({}) => {
  const res = await apiHelper.getVersion();
  return res.content;
};

// Users
export const getUsers = async ({}) => {
  const res = await apiHelper.getUsers();
  return adminService.setUsers(res.content);
};

export const getUser = async ({}, id) => {
  const res = await apiHelper.getUser(id);
  return adminService.setUser(res.content);
};

export const postUser = async ({}, form) => {
  const res = await apiHelper.postUser(
    form.password,
    form.username,
    form.first_name,
    form.last_name,
    form.email,
    form.is_staff
  );

  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Utilisateur crée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la creation !",
    });
    return false;
  }
};

export const updateUser = async ({}, form) => {
  const res = await apiHelper.updateUser(
    form.id,
    form.password,
    form.username,
    form.first_name,
    form.last_name,
    form.email,
    form.is_staff
  );
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Utilisateur modifié",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const deleteUser = async ({}, id) => {
  const res = await apiHelper.deleteUser(id);
  if (res.status == 204) {
    store.commit("message", {
      status: "success",
      text: "Utilisateur supprimé !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la supresssion !",
    });
    return false;
  }
};

// Promoters
export const getPromoters = async ({}, filter) => {
  const res = await promoterHelper.getPromoters(filter);
  return adminService.setPromoters(res.content);
};

export const searchPromoter = async ({}, input) => {
  const res = await promoterHelper.searchPromoter(input);
  return adminService.setPromoters(res.content);
};

export const getPromoter = async ({}, id) => {
  const res = await promoterHelper.getPromoter(id);
  return adminService.setPromoter(res.content);
};

export const getPromoterContacts = async ({}, id) => {
  const res = await promoterHelper.getPromoterContacts(id);
  return adminService.setPromoterContacts(res.content);
};

export const getPromoterAgencies = async ({}, id) => {
  const res = await promoterHelper.getPromoterAgencies(id);
  return adminService.setPromoterAgencies(res.content);
};

export const getPromoterPermits = async ({}, filter) => {
  const res = await promoterHelper.getPromoterPermits(filter);
  return adminService.setPromoterPermits(res.content);
};

export const postPromoter = async ({}, form) => {
  const res = await promoterHelper.postPromoter(
    form.name,
    form.director_name,
    form.head_office_address
  );

  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Promoteur crée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la creation !",
    });
    return false;
  }
};

export const postPromoterWithAction = async ({}, form) => {
  const res = await promoterHelper.postPromoter(
    form.name,
    form.director_name,
    form.head_office_address
  );

  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Promoteur crée !",
    });
    return router.push("/promoters?size=30&page=1");
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la creation !",
    });
    return false;
  }
};

export const updatePromoter = async ({}, form) => {
  const res = await promoterHelper.updatePromoter(
    form.id,
    form.name,
    form.director_name,
    form.head_office_address
  );
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Promoteur modifié !",
    });
    return router.push("/promoters/" + form.id);
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const deletePromoter = async ({}, id) => {
  const res = await promoterHelper.deletePromoter(id);
  if (res.status == 204) {
    store.commit("message", {
      status: "success",
      text: "Promoteur supprimé !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la supresssion !",
    });
    return false;
  }
};

export const getPromotersInSelect = async ({}) => {
  const res = await promoterHelper.getPromotersInSelect();
  return adminService.setPromoters(res.content);
};

// Cities
export const getCities = async ({}, filter) => {
  const res = await cityHelper.getCities(filter);
  return adminService.setCities(res.content);
};

export const getCity = async ({}, id) => {
  const res = await cityHelper.getCity(id);
  return adminService.setCity(res.content);
};

export const searchCity = async ({}, input) => {
  const res = await cityHelper.searchCity(input);
  return adminService.setCities(res.content);
};

export const rateCity = async ({}, form) => {
  const res = await cityHelper.rateCity(form);
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Note modifiée !",
    });
    return router.push("/cities/" + form.id);
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const postCity = async ({}, form) => {
  const res = await cityHelper.postCity(form.name);

  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Ville crée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la creation !",
    });
    return false;
  }
};

export const updateCity = async ({}, form) => {
  const res = await cityHelper.updateCity(form);
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Ville modifiée !",
    });
    return true;
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const deleteCity = async ({}, id) => {
  const res = await apiHelper.cityHelper(id);
  if (res.status == 204) {
    store.commit("message", {
      status: "success",
      text: "Ville supprimée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la supresssion !",
    });
    return false;
  }
};

export const getCityContacts = async ({}, id) => {
  const res = await cityHelper.getCityContacts(id);
  return adminService.setCityContacts(res.content);
};

export const getCityPositions = async ({}) => {
  const res = await cityHelper.getCityPositions();
  return adminService.setCityPositions(res.content.results);
};

export const postCityContact = async ({}, form) => {
  const res = await cityHelper.postCityContact(form);
  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Contact crée !",
    });
    return res;
  } else {
    store.commit("message", {
      status: "danger",
      text: ["Les informations saisies ne sont pas correctes !"],
    });
    return false;
  }
};

export const updateCityContact = async ({}, form) => {
  const res = await cityHelper.updateCityContact(form);
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Contact mis à jour !",
    });
    return res;
  } else {
    store.commit("message", {
      status: "danger",
      text: ["Les informations saisies ne sont pas correctes !"],
    });
    return false;
  }
};

export const deleteCityContact = async ({}, id) => {
  const res = await cityHelper.deleteCityContact(id);
  if (res.status == 204) {
    store.commit("message", {
      status: "success",
      text: "Contact supprimé !",
    });
    return res;
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la suppression",
    });
    return false;
  }
};

export const getCitiesContacts = async ({}, filter) => {
  const res = await cityHelper.getCitiesContacts(filter);
  return adminService.setCitiesContacts(res.content);
};

/// Agency
export const getAgencies = async ({}) => {
  const res = await agencyHelper.getAgencies();
  return adminService.setAgencies(res.content);
};

export const searchAgency = async ({}, input) => {
  const res = await agencyHelper.searchAgency(input);
  return adminService.setAgencies(res.content);
};

export const getAgency = async ({}, id) => {
  const res = await agencyHelper.getAgency(id);
  return adminService.setAgency(res.content);
};

export const postAgency = async ({}, form) => {
  const res = await agencyHelper.postAgency(
    form.name,
    form.phone_number,
    form.promoter,
    form.address
  );

  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Agence créée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la création !",
    });
    return false;
  }
};

export const updateAgency = async ({}, form) => {
  const res = await agencyHelper.updateAgency(
    form.id,
    form.name,
    form.phone_number,
    form.promoter,
    form.address
  );
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Agence modifiée !",
    });
    return res;
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const deleteAgency = async ({}, id) => {
  const res = await agencyHelper.deleteAgency(id);
  if (res.status == 204) {
    store.commit("message", {
      status: "success",
      text: "Agence supprimée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la supresssion !",
    });
    return false;
  }
};

export const getAgencyContacts = async ({}, id) => {
  const res = await agencyHelper.getAgencyContacts(id);
  return adminService.setAgencyContacts(res.content);
};

export const getAgencyPermits = async ({}, filter) => {
  const res = await agencyHelper.getAgencyPermits(filter);
  return adminService.setAgencyPermits(res.content);
};

export const getAgenciesInSelect = async ({}) => {
  const res = await agencyHelper.getAgenciesInSelect();
  return adminService.setAgencies(res.content);
};

// Addresses
export const getAddresses = async ({}) => {
  const res = await apiHelper.getAddresses();
  return adminService.setAddresses(res.content);
};

export const getAddresse = async ({}, id) => {
  const res = await apiHelper.getAddresse(id);
  return res.content;
};

export const postAddress = async ({}, form) => {
  const res = await apiHelper.postAddress(
    parseInt(form.number),
    form.street,
    form.city,
    form.zipcode
  );

  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Adresse crée !",
    });
    return res.content;
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la creation !",
    });
    return false;
  }
};

export const updateAddresse = async ({}, form) => {
  const res = await apiHelper.updateAddresse(
    form.id,
    form.name,
    form.phone_number,
    form.promoter,
    form.address
  );
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Adresse modifiée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const deleteAddresse = async ({}, id) => {
  const res = await apiHelper.deleteAddresse(id);
  if (res.status == 204) {
    store.commit("message", {
      status: "success",
      text: "Addresse supprimée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la supresssion !",
    });
    return false;
  }
};

/// Contacts
export const getContacts = async ({}, filter) => {
  const res = await apiHelper.getContacts(filter);
  return adminService.setContacts(res.content);
};

export const getContactsInPermit = async ({}) => {
  const res = await apiHelper.getContactsInPermit();
  return adminService.setContacts(res.content);
};

export const getContact = async ({}, id) => {
  const res = await apiHelper.getContact(id);
  return adminService.setContact(res.content);
};

export const getPromotersContact = async ({}, id) => {
  const res = await apiHelper.getPromotersContact(id);
  if (res.status == 200) {
    return res.content;
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const getContactPermits = async ({}, filter) => {
  const res = await apiHelper.getContactPermits(filter);
  return adminService.setContactPermits(res.content);
};

export const getContactFollowups = async ({}, id) => {
  const res = await apiHelper.getContactFollowUps(id);
  if (res.status == 200) {
    return res.content;
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de l'obtention des suivis",
    });
    return false;
  }
};

export const getContactFollowupsWithoutPermit = async ({}, id) => {
  const res = await apiHelper.followUpsWithoutPermit(id);
  if (res.status == 200) {
    return res.content;
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de l'obtention des suivis",
    });
    return false;
  }
};

export const getContactFollowupsWithPermit = async ({}, id) => {
  const res = await apiHelper.followUpsWithPermit(id);
  if (res.status == 200) {
    return res.content;
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de l'obtention des suivis",
    });
    return false;
  }
};

export const getContactCallbackDates = async ({}, id) => {
  const res = await apiHelper.getContactCallbackDates(id);
  return adminService.setContactCallbackDates(res.content);
};

export const deleteCallbackDate = async ({}, id) => {
  const res = await apiHelper.deleteCallbackDate(id);
  if (res.status == 204) {
    store.commit("message", {
      status: "success",
      text: "Dates de rappel supprimé !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la supresssion !",
    });
    return false;
  }
};

export const postContact = async ({}, form) => {
  const res = await apiHelper.postContact(
    form.first_name,
    form.last_name,
    form.phone_number,
    form.second_phone_number,
    form.email,
    form.position,
    form.agency_id,
    form.promoter_id
  );

  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Contact crée !",
    });
    return res.content.id;
  } else {
    let allError = validationHelper.formatError(res.content);
    store.commit("message", {
      text: allError,
      status: "danger",
    });
    return false;
  }
};

export const postContactWithAction = async ({}, form) => {
  const res = await apiHelper.postContact(
    form.first_name,
    form.last_name,
    form.phone_number,
    form.second_phone_number,
    form.email,
    form.position,
    form.agency_id,
    form.promoter_id
  );

  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Contact crée !",
    });
    return router.push("/contacts");
  } else {
    let allError = validationHelper.formatError(res.content);
    store.commit("message", {
      text: allError,
      status: "danger",
    });
    return false;
  }
};

export const updateContact = async ({}, form) => {
  const res = await apiHelper.updateContact(
    form.id,
    form.first_name,
    form.last_name,
    form.phone_number,
    form.second_phone_number,
    form.email,
    form.position,
    form.promoter,
    form.agency_id
  );
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Contact modifié !",
    });
    return router.push("/contacts/" + form.id);
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const rateContact = async ({}, form) => {
  const res = await apiHelper.rateContact(form.id, form.relation_quality);
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Note modifiée !",
    });
    return router.push("/contacts/" + form.id);
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const deleteContact = async ({}, id) => {
  const res = await apiHelper.deleteContact(id);
  if (res.status == 204) {
    store.commit("message", {
      status: "success",
      text: "Contact supprimé !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la supresssion !",
    });
    return false;
  }
};

/// Permits
export const getPermits = async ({}, filter) => {
  const res = await apiHelper.getPermits(filter);
  return adminService.setPermits(res.content);
};

export const getPermit = async ({}, id) => {
  const res = await apiHelper.getPermit(id);
  return adminService.setPermit(res.content);
};

export const searchPermit = async ({}, input) => {
  const res = await apiHelper.searchPermit(input);
  return adminService.setPermits(res.content);
};

export const searchContact = async ({}, input) => {
  const res = await apiHelper.searchContact(input);
  return adminService.setContacts(res.content);
};

export const searchAddress = async ({}, input) => {
  const res = await apiHelper.searchAddress(input);
  return adminService.setAddresses(res.content);
};

export const getPermitContacts = async ({}, id) => {
  const res = await apiHelper.getPermitContacts(id);
  return adminService.setPermitContacts(res.content);
};

export const getPermitFollowUps = async ({}, id) => {
  const res = await apiHelper.getPermitFollowUps(id);
  return adminService.setPermitFollowUps(res.content);
};

export const updatePermit = async ({}, data) => {
  const res = await apiHelper.updatePermit(data.id, data.contact);
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Permis modifié !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const updatePromoterContact = async ({}, data) => {
  const res = await apiHelper.updatePromoterContact(
    data.promoter,
    data.contact_id
  );
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Promoteur modifié !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const updatePermitPrincipalContact = async ({}, form) => {
  const res = await apiHelper.updatePermitPrincipalContact(
    form.id,
    form.principal_contact
  );
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Contact principal modifié !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const updatePermitProspection = async ({}, form) => {
  const res = await apiHelper.updatePermitProspection(
    form.id,
    form.owner,
    form.discussion_stage,
    form.specific_case,
    form.referrers
  );
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Prospection modifiée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const deletePermit = async ({}, id) => {
  const res = await apiHelper.deletePermit(id);
  if (res.status == 204) {
    store.commit("message", {
      status: "success",
      text: "Permis supprimé !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la supresssion !",
    });
    return false;
  }
};

export const getPermitsInSelect = async ({}) => {
  const res = await apiHelper.getPermitsInSelect();
  return adminService.setPermits(res.content);
};

// Follow ups
export const postFollowUp = async ({}, form) => {
  const res = await apiHelper.postFollowUp(
    form.contact,
    form.message,
    form.permit
  );

  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Message de suivi ajouté !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de l'ajout !",
    });
    return false;
  }
};

export const updateFollowUp = async ({}, data) => {
  const res = await apiHelper.updateFollowUp(data.id, data.message);
  if (res.status == 200) {
    store.commit("message", {
      status: "success",
      text: "Message de suivi modifié !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la modification !",
    });
    return false;
  }
};

export const deleteFollowUp = async ({}, id) => {
  const res = await apiHelper.deleteFollowUp(id);
  if (res.status == 204) {
    store.commit("message", {
      status: "success",
      text: "Message de suivi supprimé !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la supresssion !",
    });
    return false;
  }
};

// Callback dates
export const getCallBackDates = async ({}, filter) => {
  const res = await apiHelper.getCallBackDates(filter);
  return adminService.setCallBackDates(res.content, filter.type);
};

export const postCallbackDate = async ({}, form) => {
  const res = await apiHelper.postCallbackDate(
    form.contact_id,
    form.value,
    form.user
  );
  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Date de rappel ajoutée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de l'ajout !",
    });
    return false;
  }
};

// Paginations
export const nextPaginate = async ({}, url) => {
  let urlArray = url.split("/");
  urlArray.splice(0, 1);
  urlArray.splice(1, 1);
  urlArray.splice(1, 1);
  const res = await paginationsHelper.nextPaginate(urlArray.join("/"));
  return res.content;
};

export const previousPaginate = async ({}, url) => {
  let urlArray = url.split("/");
  urlArray.splice(0, 1);
  urlArray.splice(1, 1);
  urlArray.splice(1, 1);
  const res = await paginationsHelper.previousPaginate(urlArray.join("/"));
  return res.content;
};

// Cities callback dates
export const postCityCallbackDate = async ({}, form) => {
  const res = await cityHelper.postCityCallbackDate(
    form.city,
    form.value,
    form.owner
  );
  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Date de rappel ajoutée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de l'ajout !",
    });
    return false;
  }
};

export const getCityCallbackDates = async ({}, id) => {
  const res = await cityHelper.getCityCallbackDates(id);
  return adminService.setCityCallbackDates(res.content);
};

export const deleteCityCallbackDate = async ({}, id) => {
  const res = await cityHelper.deleteCityCallbackDate(id);
  if (res.status == 204) {
    store.commit("message", {
      status: "success",
      text: "Date de rappel supprimée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la supresssion !",
    });
    return false;
  }
};

export const getCitiesCallbackDates = async ({}, filter) => {
  const res = await cityHelper.getCitiesCallbackDates(filter);
  return adminService.setCitiesCallbackDates(res.content);
};

// City comments
export const postCityComment = async ({}, form) => {
  const res = await cityHelper.postCityComment(
    form.city,
    form.content,
    form.author
  );
  if (res.status == 201) {
    store.commit("message", {
      status: "success",
      text: "Commentaire ajouté !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de l'ajout !",
    });
    return false;
  }
};

export const getCityComments = async ({}, id) => {
  const res = await cityHelper.getCityComments(id);
  return adminService.setCityComments(res.content);
};

export const deleteCityComment = async ({}, id) => {
  const res = await cityHelper.deleteCityComment(id);
  if (res.status == 204) {
    store.commit("message", {
      status: "success",
      text: "Commentaire supprimée !",
    });
  } else {
    store.commit("message", {
      status: "danger",
      text: "Erreur lors de la supresssion !",
    });
    return false;
  }
};
